const SEO = [
	{
		page: "home",
		description: "Sono uno sviluppatore Web.",
		keywords: ["Lorenzo", "Lorenzo M", "Lorenzo Martin"],
	},

	{
		page: "su-di-me",
		description: "Sono uno sviluppatore Web.",
		keywords: ["Lorenzo", "Lorenzo M", "Lorenzo Martin"],
	},

	{
		page: "articoli",
		description:
			"Collezione su articoli rigurdantilo sviluppo sofware e la programmazione.",
		keywords: ["Lorenzo", "Lorenzo M", "Lorenzo Martin"],
	},

	{
		page: "progetti",
		description:
			"Durante il tempo libero amo realizzare side-project e qui potete trovarli.",
		keywords: ["Lorenzo", "Lorenzo M", "Lorenzo Martin"],
	},

	{
		page: "contatti",
		description:
			"Se sei interessato a collaborare o hai bisogno di aiuto, contattami.",
		keywords: ["Lorenzo", "Lorenzo M", "Lorenzo Martin"],
	},
];

export default SEO;
