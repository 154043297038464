const INFO = {
	main: {
		title: "Lorenzo Martin Sviluppatore",
		name: "Lorenzo Martin",
		email: "lorenzomartin1999@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/martslore",
		linkedin: "https://www.linkedin.com/in/lorenzomartin4/",
		instagram: "https://www.instagram.com/_lorenzo_martin/",
	},

	homepage: {
		title: "mi chiamo Lorenzo Martin e sono un Developer!",
		description:
			"Mi sono laureato in Informatica presso l'Università di Udine. Amo le sfide e cerco sempre di rimanere al passo con le nuove tecnologie per creare soluzioni innovative. Esplora il mio portfolio per scoprire i miei progetti più significativi e il mio approccio alla progettazione e allo sviluppo.",
	},

	about: {
		title: "Sono Lorenzo.",
		description:
			"",
	},
	projects: [
		{
			title: "DELL XPS 7390 con Fedora 39",
			description:
				"In questo progetto ho documentato la configurazione del mio laptop DELL XPS 7390 con Fedora 39.",
			logo: "/icons/linux-logo.png",
			linkText: "Vedi Progetto",
			link: "https://github.com/martslore/DELL-XPS-7390-Linux-Config",
		},

		{
			title: "Il mio sito web personale",
			description:
				"In questo progetto ho realizzato il sito Web che state utilizzando utilizzando React e un repository Github open source.",
			logo: "/icons/react-logo.png",
			linkText: "Vedi Progetto",
			link: "https://github.com",
		}
	],
};

export default INFO;
